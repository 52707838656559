<div class="modal-header" automationid="Section_LogoutHeader">
  <h3 class="modal-title">Warning</h3>
  <button type="button" class="close" aria-label="Close" mat-dialog-close tabindex="1" automationid="BtnClose">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" id="modal-body" automationid="Section_LogoutBody">
  <p>Your session is about to expire in <span id="the_time">{{ counter }}</span> seconds, do you want to continue working?</p>
</div>

<div class="modal-footer" style="justify-content: space-between;" automationid="Section_LogoutFooter">
  <button type="button" mat-flat-button color="primary" class="float-start pull-left" aria-label="Close" tabindex="1" (click)="logout()" automationid="BtnLogOut">
    <i class="fa fa-power-off" aria-hidden="true"></i>
    Log Out
  </button>
  <button type="button" mat-flat-button color="accent" tabindex="1" (click)="extendSession()" automationid="BtnContinueWorking">
    Continue Working
    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
  </button>
</div>
