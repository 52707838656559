/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ApiHttpService } from './api.http.base.service';
import { ApiSelectorsService } from './api.selectors.service';
import { ApiStoreActions } from './api.actions';
import { ApiMap } from './api.map';
import { AppStore } from '../store';
import { AppSettings } from '../../../shared/app.settings';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of as ObservableOf, Subscription } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';
import { LoanUtils } from '../../utils/loan-utils';
import { AppConfigService } from '../../services/app-config.service';
import { sections2009 } from 'src/assets/config/2009/01741/src/sections';
import { sections2020 } from 'src/assets/config/2020/01741/src/sections';
import {
  ILoanDateViewModel,
  IBaseLoanViewModel,
  ILookupItem,
  ICreditWithBorrowersViewModel,
  IRequestedDocumentViewModel,
  IBorrowerDocumentViewModel,
  ILoanSnapshotViewModel,
  ICPOSIVOAListViewModel,
  ILeadSourceInfoViewModel,
  IManageUserAccountViewModel,
  IManageUserAccountsViewModel,
  IUserAccountViewModel,
  IIntegrationVendorViewModel,
  IAppraisalOrderProductViewModel,
  ISecureLinkAuthenticationViewModel,
  IAuthenticationRequestViewModel,
  IAuthenticationResponseViewModel,
  ILoanContact,
  ICompanyProfileHierarchyViewModel,
  ICompanyInfoViewModel,
  IAuditLogRequest,
  IVOAConfigurationViewModel,
  IZipCalculationData,
  IFinancialInstitutionResponseViewModel,
  IAdditionalApplicant,
  IPreviousContactIdsViewModel
} from 'src/app/shared/models';

import {
  URLAFormTypeEnum,
  EmploymentVerificationTypeEnum,
  ProductTypeEnum,
  AppraisalRequestTypeEnum,
  ICPOSSrvApiResponse,
  ICPOSSrvApiResponseCased,
  ILoanViewModel,
  ICPOSAppState,
  ICPOSIsValidUserAndActiveByUserNameResponse,
  ICPOSIsExistingUserResponse

} from 'src/app/shared/models';
import { constants, api } from 'src/app/shared/global';

// TODO: Add dynamic urls to caching Api
const timeStamp = new Date().getTime();


@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiHttpService {
  // remarkably, the system needs this exact user account id for several services (this is dev@meandmyloan.com)
  //private static readonly serviceAcctId = 74391;

  public appState$ = new BehaviorSubject<ICPOSAppState>({
    loaded: false,
    form1003: {
      isActive: null,
      isStarted: null,
      completedDate: null,
      state: [],
      indexes: {},
      pagePath: [],
      urlaModalShown: null
    },
    dashboard: {
      isActive: null,
      isStarted: null,
      completedDate: null,
    },
  });

  /** BaseLoan endpoint */
  public baseLoan = {
    get: (update?: boolean) => {
      const loanId = this.settings.loanId;
      const borrowerUserAccountId = this.settings.userId;
      const loUserAccountId = this.settings.lsidUserId;
      const userAccountId = this.settings.serviceAccountId;
      const endpoint = `${this.settings.apiUrl}${ApiMap.baseLoan.endpoint}`;
      const params = {
        borrowerUserAccountId,
        loanId,
        loUserAccountId,
        systemSource: 1,
        userAccountId,
      };

      return this.getStore<IBaseLoanViewModel>(endpoint, ApiMap.baseLoan, update, params).pipe(
        tap(loan => {
          if (loan) {
            // TODO: This should be part of the base loan endpoint, but adding this here for now for expediency
            loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator = null;
            loan.transactionInfo.borrowers[0].declarationsInfo.permanentResidentAlienIndicator = null;
            loan.transactionInfo.borrowers[0].isPrimaryBorrower = true;
            loan.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator = null;
            loan.transactionInfo.borrowers[1].declarationsInfo.permanentResidentAlienIndicator = null;

            this.cacheSet(`${this.settings.apiUrl}${ApiMap.megaLoan.endpoint}`, loan);
          }
        }),
      );
    },
  };

  public client1003Config = {
    get: (urla: URLAFormTypeEnum = URLAFormTypeEnum.URLA2020) => {
      this.select.client1003Config$.next({
        data: urla === URLAFormTypeEnum.URLA2009 ? sections2009 : sections2020,
      });
      return this.select.client1003Config$;
    },
  };

  /** MegaLoan endpoint */
  public megaLoan = {
    saveToSettings: (loan: ILoanViewModel) => {
      if (loan && loan.transactionInfo) {
        if (
          loan.transactionInfo.loanApplications &&
          loan.transactionInfo.loanApplications.length &&
          loan.transactionInfo.loanApplications[0].loanApplicationId
        ) {
          this.settings.loanId = loan.loanId;
          this.settings.loanApplicationId = loan.transactionInfo.loanApplications[0].loanApplicationId;
        }
        this.settings.borrowerId = LoanUtils.getBorrowerIdByUserAccountId(loan, this.settings.userId);
        this.settings.primaryAppSixPieces = loan.primaryAppSixPieces;
        if (!this.settings.borrowerId) {
          console.warn(`No matching borrower ID was found. This may cause problems within the application`);
        }
      }

      // TFS 316267: LO Reassignment Enhancement
      // Store original LO ID for zip lookup
      if (!this.settings.loUserIdOriginal && loan && loan.conciergeId) {
        this.settings.loUserIdOriginal = `${loan.conciergeId}`;
      }
    },
    get: (update?: boolean, isInitial?: boolean) => {
      const callTimestamp = timeStamp;
      const loanId = this.settings.loanId;
      const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}${ApiMap.megaLoan.endpoint}`;
      const params = {
        client_id: 'client',
        loanId,
        redirect_uri: 'https:%2F%2Fexample.com',
        response_type: 'token',
        scope: 'scope',
        userAccountId,
        userId: constants.User,
        callTimestamp,
        isInitial,
      };
      return this.getStore<ILoanViewModel>(endpoint, ApiMap.megaLoan, update, params).pipe(
        switchMap(loan => {
          // Check for valid loan
          // No more exception will be thrown for newly created loan from eagerload API
          // But rather check if loan id is defined
          if (!(loan && loan.transactionInfo && loan.loanId != null && loan.loanId != "00000000-0000-0000-0000-000000000000")) {
            return this.baseLoan.get(true);
          } else {
            return ObservableOf(loan);
          }
        }),
        tap(loan => {
          // Determine URLA type
          this.settings.urla = loan.urlaFormType === URLAFormTypeEnum.URLA2009 ? URLAFormTypeEnum.URLA2009 : URLAFormTypeEnum.URLA2020;

          // Check if loan is from EagerLoad API or BaseLoan API
          if (!LoanUtils.isBaseLoan(loan)) {
            this.settings.canSaveAppState = true;
          }
          // Save properties from the loan
          this.megaLoan.saveToSettings(loan);
        }),
      );
    },
    save: (megaLoan: any) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.megasave}?userAccountId=${this.settings.serviceAccountId}`;
      return this.http.post<ILoanViewModel>(endpoint, megaLoan).pipe(
        tap(() => {
          this.settings.canSaveAppState = true;
        }),
      ); // , ApiMap.megaLoan
    },
    submit: () => {
      const loanId = this.settings.loanApplicationId;
      const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.consumerappsubmit}`;
      const params = {
        loanId,
        userAccountId,
      };
      return this.http.post(endpoint, null, { params });
    },
    resetCache: () => {
      this.cacheSet(`${this.settings.apiUrl}${ApiMap.megaLoan.endpoint}`, null);
    },
  };

  // `${this.settings.apiUrl}${ApiMap.config1003.endpoint}`
  // `https://dev04loancenter.cloudvirga.com/${api.rootapi}/Configuration/GetCloverConfiguration?leadSourceId=1693&keyName=clover.config.default`
  public config1003 = {
    get: (lsidSrc: string) => {
      const lsid = lsidSrc ? lsidSrc : constants.defaultlsid; // Default setting if lsid is null
      const keyName = this.settings.urla === URLAFormTypeEnum.URLA2009 ? constants.config2009 : constants.config2020;
      return this.getStore<CvFormBuilder.Section[]>(`${this.settings.apiUrl}${ApiMap.config1003.endpoint}?leadSourceId=${lsid}&keyName=${keyName}`, ApiMap.config1003);
    },
  };

  public dataFields = {
    get: (enumId: number) => {
      const timestamp = new Date().getTime();
      const userAccountId = this.settings.userId;
      return this.http
        .get<ICPOSSrvApiResponse<ILookupItem[]>>(
          `${
          this.settings.apiUrl
          }/${api.root}/${api.getlookupbylookupcategory}?callTimestamp=${timestamp}&lookupCategoryEnum=${enumId}&userId=${userAccountId}`,
        )
        .pipe(map(res => res.response));
    },
  };

  public credit = {
    run: () => {
      const url = `${this.settings.apiUrl}/${api.root}/${api.credit}`;
      //const sixPiecesCompleted = LoanUtils.areSixPiecesAcquiredForAllLoanApplications
      const params = {
        areSixPiecesCompleted: this.settings.primaryAppSixPieces.toString(),
        borrowerId: '00000000-0000-0000-0000-000000000000',
        creditReportIdentifier: '',
        loanApplicationId: this.settings.loanApplicationId,
        paidOffFreeAndClear: 'false',
        previousActiveSystem: '2',
        requestType: '1',
        userAccountId: this.settings.serviceAccountId,
        isCallerConsumerSite: 'true'
      };
      return this.http.get(url, { observe: 'response', params });
    },
    get: () => {
      const url = `${this.settings.apiUrl}/${api.root}/${api.credit}`;
      const params = {
        accountId: this.settings.serviceAccountId,
        applicationId: this.settings.loanApplicationId,
      };
      return this.http.get<ICreditWithBorrowersViewModel>(url, { params });
    },
  };

  /** // Feature 383985 - Get Loan Number API */

  public getLoanNumber = {
    get: () => {
      const loanId = this.settings.loanId;
      const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getLoanNumber}`;
      const params = {
        loanId,
        userAccountId,
      };
      return this.http.get(endpoint, { params });
    }
  }

  /** Borrower needs list endpoint */
  public bnl = {
    get: (update?: boolean) => {
      const loanId = this.settings.loanId;
      const loanAppId = this.settings.loanApplicationId;
      const endpoint = `${this.settings.apiUrl}${ApiMap.bnl.endpoint}`;
      const params = {
        loanAppId,
        loanId,
        userId: constants.User,
      };
      return this.getStore<IRequestedDocumentViewModel[]>(endpoint, ApiMap.bnl, update, params);
    },
    generate: (update?: boolean) => {
      const loanId = this.settings.loanId;
      const loanAppId = this.settings.loanApplicationId;
      const userAccountId = this.settings.userId;
      const timestamp = new Date().getTime();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.borrowerneedsservicegenerate}`;
      const params = {
        callTimestamp: timestamp,
        loanAppId,
        loanId,
        userId: constants.User,
        userAccountId,
      };
      return this.getStore<IRequestedDocumentViewModel[]>(endpoint, ApiMap.bnl, update, params);
    },
  };

  /** Borrower documents endpoint */
  public documents = {
    get: (update?: boolean) => {
      const timestamp = new Date().getTime();
      const loanId = this.settings.loanApplicationId;
      const endpoint = `${this.settings.apiUrl}${ApiMap.documents.endpoint}`;
      const params = {
        callTimestamp: timestamp,
        loanId,
        userId: constants.User,
      };
      return this.getStore<IBorrowerDocumentViewModel[]>(endpoint, ApiMap.documents, update, params);
    },
  };

  /** All borrower loans endpoint */
  public activeLoanSnapshots = {
    get: (update?: boolean) => {
      const timestamp = new Date().getTime();
      const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}${
        ApiMap.activeLoanSnapshots.endpoint
        }?callTimestamp=${timestamp}&userAccountId=${userAccountId}&userId=User`;
      return this.getStore<ILoanSnapshotViewModel[]>(endpoint, ApiMap.activeLoanSnapshots, update);
    },
  };

  /** Borrower eVerify endpoint */
  public everify = {
    getIframeUrl: (loanId: string, borrowerId: string, assetInfoId: string) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.enrollandgetwidget}`;
      return this.http.get<ICPOSSrvApiResponse<{ value: string }>>(endpoint, {
        params: {
          assetInfoId,
          borrowerId,
          callTimestamp,
          loanId,
          userId: constants.User,
        },
      });
    },
    getAccountList: (loanId: string, borrowerId: string, financialInstitutionId: number) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getverifiedaccountlist}`;
      return this.http.get<ICPOSSrvApiResponse<ICPOSIVOAListViewModel>>(endpoint, {
        params: {
          borrowerId,
          callTimestamp,
          financialInstitutionId: `${financialInstitutionId}`,
          loanId,
          userId: constants.User,
        },
      });
    },
    processAccounts: (data: any) => {
      this.analytics.trackEvent('Secure Account Linking Login', {
        'Account Login': 'Yes',
        // 'Cancel': 'No'
      });
      this.analytics.trackEvent('Secure Account Linking Review', {
        'Accounts sent to Lender': 'Yes',
      });
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/api/EVerifyAssetService/ProcessVerifiedAccounts`;
      return this.http.post<ICPOSSrvApiResponse<{ result: ICPOSIVOAListViewModel}>>(endpoint, data, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      });
    },
  };

  /** Finding LO for new loan added to the same useraccount */
  public leadSourceForNewLoan = {
    get: () => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getleadsourceforborrower}?url=${encodeURIComponent(this.settings.landingUrl)}&borrowerAccountId=${this.settings.userId}`;
      const params = {
        CallTimestamp: new Date().getTime().toString(),
        UserID: this.settings.userId
      };
      return this.getStore<ILeadSourceInfoViewModel>(endpoint, ApiMap.broker, false, params).pipe(
        tap(broker => {
          if (!broker) return;
        }),
        map(broker => broker && broker.leadSourceId ? broker : null)
      );
    },
  };

  /** Borrower eVerification of income and assets endpoint */
  public evoievoe = {
    subscription: {},
    runEmploymentVerification: (type: EmploymentVerificationTypeEnum, loan: ILoanViewModel, borrowerId: string, isRunNewVerificationRequest: boolean) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.runemploymentverification}`;
      return this.http.post<ICPOSSrvApiResponse<ILoanViewModel>>(endpoint, loan, {
        params: {
          borrowerId,
          userAccountId: this.settings.userId,
          employmentVerificationType: type === EmploymentVerificationTypeEnum.eVOI ? '1' : '2',
          isRunNewVerificationRequest: isRunNewVerificationRequest.toString(),
        },
      });
    },
    unsubscribe: () => {
      if(this.evoievoe.subscription && (this.evoievoe.subscription as Subscription).unsubscribe) {
        (this.evoievoe.subscription as Subscription).unsubscribe();
      }
    }
  };

  /** Update user account */
  public saveUserAccount = {
    save: (request: IManageUserAccountViewModel) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.saveuseraccount}`;
      return this.http.post<IManageUserAccountsViewModel>(endpoint, request);
    }
  };

  public user = {
    getUserAccountById: (userAccountId: number) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getuseraccountbyid}`;
      const params = {
        userAccountId: `${userAccountId}`,
      };
      return this.http.get<IUserAccountViewModel>(endpoint, { params });
    },
    saveUserAccount: this.saveUserAccount.save,
  };

  /** Borrower eSign endpoint */
  public esign = {
    getVendorForLoanByProduct: (loanId: string, productId: ProductTypeEnum) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getvendorforloanbyproduct}`;
      return this.http.get<ICPOSSrvApiResponse<IIntegrationVendorViewModel>>(endpoint, {
        params: {
          callTimestamp,
          userId: constants.User,
          loanId,
          productId: productId.toString(),
        },
      });
    },
    getIframeUrl: (request: any) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getsigningroom}`;
      return this.http.post(endpoint, request, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      });
    },
    confirmSignature: (params: any) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.onsignatureevent}`;
      return this.http.get(endpoint, { responseType: 'text', params });
    },
    setDocumentPreview: (loanApplicationId: string, borrowerId: string, documentId: string) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.setdocumentpreview}`;
      const params = {
        loanApplicationId,
        borrowerId,
        documentId
      };
      return this.http.get(endpoint, { params });
    },
    updateSSOeSignUserId: (borrowerId: string) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.updateSSOeSignUserId}`;
      const params = {
        borrowerId,
      };
      return this.http.get<ICPOSSrvApiResponse<boolean>>(endpoint, { params });
    }
  };

  /** Appraisals endpoints */
  public appraisal = {
    saveAppraisal: (data: any) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.saveappraisal}`;
      return this.http.post<ICPOSSrvApiResponse<boolean>>(endpoint, data, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      })
        .pipe(map(response => {
          // Catch error messages
          if (!response || !response.response || !!response.errorMsg) {
            throw new Error(response.errorMsg);
          }
        }));
    },
    submitAppraisalRequest: (loanNumber: string) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.submitappraisalrequest}`;
      return this.http.get(endpoint, {
        params: {
          appraisalRequestType: AppraisalRequestTypeEnum.RequestAppraisal.toString(),
          consumerSubmitted: 'true',
          loanId: this.settings.loanId,
          loanNumber,
          userAccountId: this.settings.userId,
          nonce: '',
          callTimestamp,
          userId: constants.User,
        },
      });
    },
    markAsComplete: (documentId: string, borrowerId: string) => {
      const callTimestamp = new Date().getTime().toString();
      const loanId = this.settings.loanId;
      const loanApplicationId = this.settings.loanApplicationId;
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.documentsviewingcomplete}`;
      return this.http.get(endpoint, {
        params: {
          callTimestamp,
          loanId,
          loanApplicationId,
          documentId,
          borrowerId,
          userId: constants.User,
        },
      });
    },
    getProductsAndFees: (data: any) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getappraisalproductsandfees}`;
      return this.http.post<ICPOSSrvApiResponse<IAppraisalOrderProductViewModel[]>>(endpoint, data, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      });
    },
  };

  /** Borrower user account endpoints */
  public userAccount = {
    getAccountInfo: (token: string) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getloancontext}`;
      return this.http.get<ICPOSSrvApiResponse<ISecureLinkAuthenticationViewModel>>(endpoint, {
        params: {
          callTimestamp,
          token,
          userId: constants.User,
        },
      });
    },
    updateAccount: (data: { authenticationRequestViewModel: IAuthenticationRequestViewModel }) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.authenticate}`;
      return this.http.post<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>(endpoint, data, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      });
    },
    /**
     * Note: As this is not very intuitive, I should point out that the
     * boolean response answers the question 'This account is already taken?'
     */
    checkAvailability: (userName: string) => {
      const callTimestamp = new Date().getTime().toString();
      // Username must be added to the URL in this manner due to the bug mentioned here:
      // https://github.com/angular/angular/issues/18261
      const encodedUserName = encodeURIComponent(userName);
      const endpoint = `${
        this.settings.apiUrl
        }/${api.root}/${api.isvaliduseraccountbyusername}?userName=${encodedUserName}`;
      return this.http.get<ICPOSSrvApiResponse<boolean>>(endpoint, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      });
    },
    checkActivationByUserName: (userName: string) => {
      // Username must be added to the URL in this manner due to the bug mentioned here:
      // https://github.com/angular/angular/issues/18261
      const encodedUserName = encodeURIComponent(userName);
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.isvaliduserandactivebyusername}?userName=${encodedUserName}`;
      return this.http.get<ICPOSSrvApiResponse<ICPOSIsValidUserAndActiveByUserNameResponse>>(endpoint);
    },
    isExistingUser: (userName: string, firstName: string, lastName: string) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/UserService/IsExistingUser`;
      return this.http.get<ICPOSSrvApiResponse<ICPOSIsExistingUserResponse>>(endpoint, {
        params: {
          userName: userName,
          firstName: firstName,
          lastName: lastName,
        },
      });
    },
    sendTemporaryPassword: (userName: string) => {
      const callTimestamp = new Date().getTime().toString();
      const loAffinityId = this.settings.lsid;
      // Username must be added to the URL in this manner due to the bug mentioned here:
      // https://github.com/angular/angular/issues/18261
      const encodedUserName = encodeURIComponent(userName);
      const endpoint = `${
        this.settings.apiUrl
        }/${api.root}/${api.generatetemporarypasswordandsendemail}?userName=${encodedUserName}`;
      // const
      return this.http.get<ICPOSSrvApiResponse<boolean>>(endpoint, {
        params: {
          callTimestamp,
          loAffinityId,
          userId: constants.User,
        },
      });
    },
    resetPassword: (data: any) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.authenticate}`;
      return this.http.post<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>(endpoint, data, {
        params: {
          callTimestamp,
          userId: constants.User,
        },
      });
    },
    sendActivationEmail: (userName: string) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.resendsecurelinkemail}`;
      return this.http.post<ICPOSSrvApiResponse<boolean>>(endpoint, null, {
        params: {
          callTimestamp,
          email: userName,
          userId: constants.User,
        },
      });
    },
  };

  /** Loan Contacts endpoint */
  public loanContacts = {
    get: (update?: boolean) => {
      const timestamp = new Date().getTime();
      const loanId = this.settings.loanId;
      let userAccountId = this.settings.userId;
      let endpoint = `${this.settings.apiUrl}${
        ApiMap.loanContacts.endpoint
        }?callTimestamp=${timestamp}&loanId=${loanId}&userAccountId=${userAccountId}&userId=User`;
      if (!loanId || !this.settings.token) {
        if (!this.settings.lsidUserId) {
          return ObservableOf([]);
        }
        userAccountId = this.settings.lsidUserId;
        endpoint = `${this.settings.apiUrl}${
          ApiMap.loanOfficerContacts.endpoint
          }?callTimestamp=${timestamp}&userAccountId=${userAccountId}&userId=User`;
      }
      return this.getStore<ILoanContact[]>(endpoint, ApiMap.loanContacts, update).pipe(
        tap(contacts => {
          if (contacts && contacts.length) {
            const lo = contacts.filter(contact => contact.loanContactType === 1)[0];
            if (lo && lo.userAccountId) {
              this.settings.loUserId = String(lo.userAccountId);
              // Add LO name to mixpanel super props
              this.analytics.mixpanelSuperProps({
                'Loan Officer Name': lo.name,
              });
            }
          }
        }),
      );
    },
    sendLOReassignmentEmail: (prevLoanOfficerId: number, newLoanOfficerId: number, prevLoasIds: string, prevLoanProcessorsIds: string) => {
      const loanId = this.settings.loanId;
      const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.loreassigned}`;
      const params = {
        loanId,
        userAccountId,
        prevLoanOfficerId: `${prevLoanOfficerId}`,
        newLoanOfficerId: `${newLoanOfficerId}`,
        prevLoasIds: `${prevLoasIds}`,
        prevLoanProcessorsIds: `${prevLoanProcessorsIds}`,
      };
      return this.http.post(endpoint, null, { params });
    },
    /** Get previous contact IDs endpoint */
    getPreviousContactIds: (loanId = this.settings.loanId) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.previousContactIds}`;
      const params = {
        loanId,
      };
      return this.http.get<IPreviousContactIdsViewModel>(endpoint, { params });
    },
  };

  /** Current Company Profile endpoint */
  public currentCompanyProfile = {
    get: (affinityId: string, userAccountId: string, update?: boolean) => {
      const callTimestamp = new Date().getTime();
      const loanId = this.settings.loanId;
      // const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}${ApiMap.currentCompanyProfile.endpoint}`;
      const params = {
        affinityId,
        callTimestamp,
        loanId,
        userAccountId,
        userId: constants.User,
      };

      return this.getStore<ICompanyProfileHierarchyViewModel>(
        endpoint,
        ApiMap.currentCompanyProfile,
        update,
        params,
      ).pipe(
        map(profile => {
          if (profile) {
            // Update super props in analytics
            this.analytics.mixpanelSuperProps({
              'Branch Id': profile.branchId,
              'Company Id': profile.companyId,
              'Channel Id': profile.channelId,
              'Division Id': profile.divisionId,
              'NMLS Number': profile.nmlsNumber,
              'Company Name': profile.companyBrandingName || profile.companyName,
            });
            this.settings.clientName = profile.companyBrandingName || profile.companyName;
            // Update the branch ID if none exists
            if (!this.settings.branchId) {
              this.settings.branchId = profile.branchId;
            }
          }
          return profile;
        }),
      );
    },
  };

  /** Company Basic Info endpoint */
  public companyBasicInfo = {
    get: () => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getcompanybasicinfo}`;
      const params = {
        callTimestamp,
        userId: constants.User,
      };
      return this.http.get<ICPOSSrvApiResponse<ICompanyInfoViewModel>>(endpoint, { params });
    },
    getForUser: (leadSourceId = this.settings.lsid) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getcompanybasicinfoforuser}`;
      const params = {
        leadSourceId,
      };
      return this.http.get<ICPOSSrvApiResponse<ICompanyInfoViewModel>>(endpoint, { params });
    },
  };

  /** Broker endpoint */
  public broker = {
    get: (leadSourceId = this.settings.lsid, update = false) => {
      const callTimestamp = new Date().getTime().toString();
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getleadsourcebasicinfo}?leadSourceId=${leadSourceId}&validate=true`;
      const params = {
        callTimestamp,
      };
      return this.getStore<ILeadSourceInfoViewModel>(endpoint, ApiMap.broker, update, params).pipe(
        tap(broker => {
          if (!broker) return;
          // If the broker ID is different that the LSID (set on instantiation), update WP config
          if (broker.leadSourceId !== this.lsidBroker) {
            this.config.loadConfig(broker.leadSourceId);
            this.lsidBroker = broker.leadSourceId;
          }

          // Set loan officer super prop
          this.analytics.mixpanelSuperProps({
            'Loan Officer': broker.firstName + ' ' + broker.lastName,
          });
        }),
        map(broker => broker && broker.emailAddress ? broker : null)
      );
    },
  };

  /** Audit Log endpoint */
  public auditLog = {
    save: (auditLog: any) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.createauditLog}?userAccountId=${this.settings.serviceAccountId}&loanId=${auditLog.loanId}`;
      return this.http.post<IAuditLogRequest>(endpoint, auditLog);
    },
    borrowerOpenedLoan: (loanId: string): void => {
      // If we are currently in the middle of an open loan log request for this loan ID then
      // simply return (bug 326498).
      if (this.activeLoanOpenLogRequestLoanIds.includes(loanId)) {
        return;
      }

      this.activeLoanOpenLogRequestLoanIds.push(loanId);
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.createBorrowerOpenedLoanAuditLog}?userAccountId=${this.settings.userId}&loanId=${loanId}`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true // We need this option to include cookies in the HTTP get request (see below).
      };

      this.http.get<IAuditLogRequest>(endpoint, httpOptions)
        .subscribe(() => {
          const index = this.activeLoanOpenLogRequestLoanIds.indexOf(loanId);

          if (index >= 0) {
            this.activeLoanOpenLogRequestLoanIds.splice(index, 1);
          }
        }, () => {
          const index = this.activeLoanOpenLogRequestLoanIds.indexOf(loanId);

          if (index >= 0) {
            this.activeLoanOpenLogRequestLoanIds.splice(index, 1);
          }
        });
    }
  };

  public voaConfiguration = {
    get: () => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.getvoaconfiguration}`;
      return this.http.get<ICPOSSrvApiResponse<IVOAConfigurationViewModel>>(endpoint)
        .pipe(
          map(apiRes => apiRes.response)
        );
    }
  }

  /** App State, IE are they on the 1003 or bnl section. Also keeps track of 1003 progress */
  public appState = {
    get: () => {
      const endpoint = `${this.settings.apiUrl}${ApiMap.appState.endpoint}/${
        this.settings.borrowerId
        }/${api.appstate}?loanid=${this.settings.loanApplicationId || this.settings.loanId}&userAccountID=${
        this.settings.userId
        }&userId=User`;
      return this.http.get<any>(endpoint).pipe(
        map(appStateNew => {
          // Set default state. On initial load appstate comes back null
          const state =
            appStateNew && appStateNew.sessionData
              ? JSON.parse(appStateNew.sessionData)
              : { form1003: {}, dashboard: {} };
          const appState: ICPOSAppState = {
            loaded: true,
            form1003: {
              isActive: null,
              isStarted: null,
              completedDate: null,
              state: [],
              indexes: {},
              pagePath: [],
              ...state.form1003,
            },
            dashboard: {
              isActive: null,
              isStarted: null,
              completedDate: null,
              ...state.dashboard,
            },
          };
          this.appState$.next(appState);
          return appState;
        }),
      );
    },
    set: (appState: ICPOSAppState, otherBorrowerId?: string) => {
      const completedDate = appState && appState.form1003 ? appState.form1003.completedDate : null;

      console.warn(`ApplicationComponent::appState::set -> completedDate: ${completedDate}`);

      const endpoint = otherBorrowerId ?
        `${this.settings.apiUrl}${ApiMap.appState.endpoint}/${
          this.settings.borrowerId
          }/${api.appstate}?loanid=${this.settings.loanApplicationId || this.settings.loanId}&userAccountID=${
          this.settings.userId
          }&otherBorrowerId=${otherBorrowerId}&userId=User` :
        `${this.settings.apiUrl}${ApiMap.appState.endpoint}/${
          this.settings.borrowerId
          }/${api.appstate}?loanid=${this.settings.loanApplicationId || this.settings.loanId}&userAccountID=${
          this.settings.userId
          }&userId=User`;
      const val = {
        sessionData: JSON.stringify(appState),
      };
      this.appState$.next(appState);
      // Check if appState can be saved before calling API
      if (!this.settings.canSaveAppState) {
        return ObservableOf(null);
      }
      return this.http.put<any>(endpoint, val);
    },
  };

  public loanApp = {
    add: (additionalApplicant: IAdditionalApplicant) => {
      const loanId = this.settings.loanApplicationId;
      const userAccountId = this.settings.userId;
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.addChild1003}`;
      const params = {
        loanId,
        userAccountId,
      };
      return this.http.post<IAdditionalApplicant>(endpoint, additionalApplicant, { params });
    },
  };

  public services = {
    /**
     * Get a loan contacts picture
     */
    pictureGetUrl: (pictureId: string) => `${this.settings.apiUrl}/${api.root}/${api.userpicture}?pictureId=${pictureId}`,
    /**
     * Get an array of guids
     */
    guidsGet: (count = 1000) =>
      this.http
        .get<ICPOSSrvApiResponseCased<string[]>>(
          `${this.settings.apiUrl}/${api.root}/${api.getguids}?count=${count}`,
        )
        .pipe(map(res => res.Response)),
    /**
     * Get address information from a zipcode
     */
    zipCodeLookup: (zipcode: string) => {
      return this.http
        .post<ICPOSSrvApiResponse<IZipCalculationData>>(
          `${this.settings.apiUrl}/${api.root}/${api.getzipdata}?callTimestamp=1554313152566&userId=User`,
          {
            zipDataRequest: {
              isLicensedStates: true,
              zipCode: zipcode,
              checkUserAndBranchLicenseForState: this.services.getCheckUserAndBranchLicenseForState(),
              branchId: this.settings.branchId,
              userAccountId: parseFloat(this.settings.loUserId),
              loanId: this.settings.loanId,
            },
          },
        )
        .pipe(map(res => res.response));
    },
    /**
     * Get array of address information from a zipcode
     */
    usZipCodeLookup: (zipCode: string, checkLicense: boolean) => {
      const url = `${this.settings.apiUrl}/${api.root}/${api.getuszipdata}`;
      const callTimestamp = new Date().getTime().toString();
      const params = {
        callTimestamp,
        userId: constants.User,
      };
      return this.http
        .post<ICPOSSrvApiResponse<IZipCalculationData[]>>(
          url,
          {
            zipDataRequest: {
              branchId: this.settings.branchId,
              checkUserAndBranchLicenseForState: this.services.getCheckUserAndBranchLicenseForState() && checkLicense,
              isLicensedStates: true,
              stateName: null,
              userAccountId: parseFloat(this.settings.loUserIdOriginal),
              zipCode,
              loanId: this.settings.loanId,
            },
          },
          { params },
        )
        .pipe(map(res => (res && res.response) || null));
    },
    /**
     * Autocomplete lookup for financial institution
     */
    financialInstitutionLookup: (institutionName: string) =>
      this.http
        .get<ICPOSSrvApiResponse<IFinancialInstitutionResponseViewModel>>(
          `${
          this.settings.apiUrl
          }/${api.root}/${api.searchfinancialinstitution}?callTimestamp=1554313152566&institutionName=${encodeURIComponent(
            institutionName,
          )}&requestedCount=5&userId=User`,
        )
        .pipe(map(res => res.response)),
    /**
     * Retrieve the closing date for new loans
     */
    getClosingDate: (loanPurpose: 'Purchase' | 'Refinance') => {
      const params = { loanPurpose };
      return this.http.get<ILoanDateViewModel>(`${this.settings.apiUrl}/${api.root}/${api.defaultclosingdate}`, { params });
    },
    /**
     * Check if state licensure rules are enabled
     */
    getCheckUserAndBranchLicenseForState: () => {
      let checkUserAndBranchLicenseForState = true;
      if (this.settings.config && this.settings.config[constants.configareastatelicensurerulesenabled]) {
        checkUserAndBranchLicenseForState = this.settings.config[constants.configareastatelicensurerulesenabled].value;
      }
      return checkUserAndBranchLicenseForState;
    }
  };

  /** Single Sign On endpoint */
  public sso = {
    initiateSignOn: () => {
      const lsId = this.settings.lsid;
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.initiatesignon}`;
      const params = {
        lsId
      };
      return this.http.get(endpoint, { params, responseType: 'text' });
    },
    authenticateWithSSSToken: (formData: FormData) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.authenticateWithSSOToken}`;
      return this.http.post<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>(endpoint, formData, { observe: 'response', withCredentials: true});
    },
    signOutSSO: () => {
      const lsId = this.settings.lsid;
      const relayState = encodeURIComponent(window.location.origin);
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.signoutsso}`;
      const params = {
        lsId,
        relayState
      };
      return this.http.post(endpoint, null, { params, responseType: 'text' });
    }
  };


  public leadNotifications = {
    processLeadCreation: (isSubmission: boolean, idleTimeout?: number) => {
      const endpoint = `${this.settings.apiUrl}/${api.root}/${api.processLeadCreation}?loanId=${this.settings.loanApplicationId}&isAppSubmission=${isSubmission}&idleTimeout=${idleTimeout}&userAccountId=${this.settings.userId}`;
      this.executeFetchCall(endpoint, null);
    }
  };
  /** A list of unique guids to use for creating new  Use with guids.shift() to remove entries as they are used */
  public guids: string[];

  private activeLoanOpenLogRequestLoanIds: string[] = [];
  private lsidBroker = this.settings.lsid;

  constructor(
    private store: Store<AppStore.Root>,
    private http: HttpClient,
    private settings: AppSettings,
    /** API Store Selectors */
    public select: ApiSelectorsService,
    private analytics: AnalyticsService,
    private config: AppConfigService
  ) {
    super(<any>http, <any>store);
  }

  /**
   * Get a unique Guid

  public guidGet() {
    if (this._guids) {
      return this._guids.shift();
    }
  }
  */

  /**
   * Reset the store, clear out all held state and data
   */
  public resetStore() {
    this.cacheClear(); // Clear cache
    this.store.dispatch(ApiStoreActions.RESET(null));
  }

  /**
   * Fix a bug with TS where super calls don't count as usage
   */
  public fixTS() {
    console.log(this.http);
  }

  public getApiStoreData<T>(apiStore$: Observable<AppStore.ApiState<T>>): Observable<T> {
    return apiStore$.pipe(
      filter(apiState => apiState && !!apiState.data),
      map(apiState => apiState && apiState.data),
    );
  }

  public executeFetchCall(url: string, body: any) {
    fetch(url, {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${this.settings.token}`,
      },
      body: body,
    });
  }
}
