import { ButtonActions, RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { AssetTypeEnum, CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const assetsPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Assets Intro Section',
    titleShow: false,
    fullscreen: true,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Assets',
          sectionTitle: 'Next, details about your <strong>financial assets</strong>.',
          sectionImage: `/assets/img/application/assets.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Please select any <strong>assets</strong> you would like to add',
    pageId: 'asset-type',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do you need to know about my assets?`,
          body: `
            <p>To check your qualification for loan products, we need to know that you have sufficient assets for the down payment (home purchase) and closing costs.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.assetType',
        placeholder: 'Asset Type',
        errorCustom: 'Selecting an option for assets type <strong>is required</strong>.',
        class: 'icon-group-4 assets-assetType',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Bank &amp;<br/> Financial Assets',
            value: 0,
          },
          {
            label: 'Gifts or Grants',
            value: 1,
          },
          {
            label: 'Other Assets',
            value: 2,
          },
          {
            label: `I don't have any assets`,
            value: 3,
          },
        ],
        visible: {
          rules: [
            {
              field: 'config.config.area.otherassets.enabled',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.assetType',
        placeholder: 'Asset Type',
        errorCustom: 'Selecting an option for assets type <strong>is required</strong>.',
        class: 'icon-group-4 assets-assetType',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Bank &amp;<br/> Financial Assets',
            value: 0,
          },
          {
            label: 'Gifts or Grants',
            value: 1,
          },
          {
            label: `I don't have any assets`,
            value: 3,
          },
        ],
        visible: {
          rules: [
            {
              field: 'config.config.area.otherassets.enabled',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
    ],
  },

  guidPageC: {
    title: 'Please enter the name of your <strong>financial institution</strong>',
    pageId: 'financial-assets',
    validatorId: CPOSPageValidatorsEnum.assets,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'asset-autocomplete',
        data: {
          field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Account Belongs to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageC2: {
    title:
      'Please choose to <strong>automatically</strong> link your account or <strong>manually</strong> input your account information',
    pageId: 'financial-assets-details',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How does secure linking work?`,
          body: `
            <h3>What is Secure Bank Linking?</h3>
            <p>Secure Bank Linking is powered by AccountChek, a third-party data provider that allows us to retrieve digital financial account statements. The service allows you to securely sign-in to over 5000 banking and financial institutions. The connection uses industry standard encryption to access your account statements. Your login and password will NOT be stored or shared with us, but we will be provided with of up to 12 months of electronic financial statements on your behalf.</p>
            <h3>Why does your lender need to view your financial account statements?</h3>
            <p>To check your qualification for loan products, we need to know that you have sufficient assets for the down payment and closing costs.</p>
            <h3>Faster way to verify your assets</h3>
            <p>Secure Bank Linking quickly, automatically and accurately analyzes your account data and securely transmits the results in a digital report to us. This lets us make better and faster decisions, which can speed up the loan process, helping you to quickly get the mortgage loan you want.</p>
            <p><a href="https://verifier.accountchek.net/tos" target="_blank">Learn more about AccountChek</a></p>
            <p>The contents within this iFrame belong to our 3rd party provide Accountchek FomFree. As such, the accessibility of this iFrame is beyond
            our control. If you have any difficulty interacting with this content please call us at 800-225-9498 or email us at <a href="mailto:customersuccess@formfree.com" target="_blank">customersuccess@formfree.com</a></p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Assets details',
        field: 'loan.$$custom.isEVerifyAsset',
        errorCustom: 'Selecting an option for assets verification <strong>is required</strong>.',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Assets Securely',
            description: 'Quickly link your account(s) to speed up your loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Assets',
            description: 'This method will require you to upload your statements',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.evoaAggreedTermsOfService',
        placeholder: 'I have read and agree to the <a href="{{loan.$$custom.evoaTermsOfServiceUrl}}" target="_blank">Terms of Service</a> to verify assets securely using AccountChek® by FormFree®.',
        formFieldType: 'checkbox',
        errorCustom: 'To Securely Verify Assets Electronically, you must first agree to the Terms of Service.',
        // showError: false,
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEVerifyAsset',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageC3: {
    title: 'Assets eVerification',
    titleShow: false,
    pageId: 'financial-assets-evoa',
    isLastPage: false,
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<p>This iframe contains content that is provided by a third party that may not be ADA compliant. 
        If you need assistance, please email itsupport@cloudvirga.com </p>`,
      }, 
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'evoa',
      },
    ],
  }, // end page

  guidPageC4: {
    title: 'Please enter information about your <strong>financial assets</strong>',
    pageId: 'financial-assets-manual',
    validatorId: CPOSPageValidatorsEnum.assets,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are bank and financial assets?`,
          body: `
            <h3>Bonds</h3>
            <p>A bond is a fixed-income instrument generally provided by corporations and government.</p>
            <h3>Bridge Loan</h3>
            <p>A short term loan to finance the purchase of a new property.</p>
            <h3>Certificate of Deposit (CD)</h3>
            <p>Also known as a CD, it is a product offered by banks and credit unions that offers a higher interest rate compared to saving accounts, where the deposited amount remains untouched for a specified period of time.</p>
            <h3>Checking</h3>
            <p>This is a deposit account that allows for frequent withdrawals and deposits. Checking accounts can be personal or joint accounts.</p>
            <h3>Money Market</h3>
            <p>This is an interest-bearing savings account offered by banks and credit unions. These accounts usually earn higher interest than regular savings accounts, but have higher balance requirements.</p>
            <h3>Mutual Fund</h3>
            <p>These are professionally managed investments that pool your money together with other investors to purchase shares of a collection of stocks, bonds, or other securities, referred to as a portfolio.</p>
            <h3>Retirement Fund (e.g. 401K, IRA)</h3>
            <p>A 401(k) is a tax-deferred retirement savings account offered by employers that may be a SEP (Simplified Employee Pension) or SIMPLE (Savings Incentive Match Plan for Employees) type of IRA.</p>
            <p>An individual retirement account (IRA) is an account set up at a financial institution that allows an individual to save for retirement with tax-free growth or on a tax-deferred basis.</p>
            <h3>Savings</h3>
            <p>A savings account is an interest-bearing deposit account held at a bank or other financial institution.</p>
            <h3>Stocks</h3>
            <p>Shares, often called stocks or shares of stock, represent the equity ownership of a corporation divided up into units, so that multiple people can own a percentage of a business.</p>
            <h3>Trust Funds</h3>
            <p>An account with funds and assets held by a trustee for the benefit of the beneficiary. Trust funds can hold a variety of assets such as money, property, stock, a business, or a combination.</p>
          `,
        },
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'asset-autocomplete',
        data: {
          field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
        placeholder: 'Name of Financial Institution',
        formFieldType: 'text', // TODO: Convert to custom control
        // dataField: 'df-institutions',
        validators: {
          required: true,
        },
      },
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].assetType',
        placeholder: 'Account Type',
        formFieldType: 'select',
        formFieldData: [
          {
            value: AssetTypeEnum.Bonds,
            label: 'Bonds',
          },
          {
            value: AssetTypeEnum.BridgeLoan,
            label: 'Bridge Loan',
          },
          {
            value: AssetTypeEnum.CD,
            label: 'CD',
          },
          {
            value: AssetTypeEnum.Checking,
            label: 'Checking',
          },
          {
            value: AssetTypeEnum.MoneyMarket,
            label: 'Money Market',
          },
          {
            value: AssetTypeEnum.MutualFunds,
            label: 'Mutual Funds',
          },
          {
            value: AssetTypeEnum.RetirementFund,
            label: 'Retirement Fund',
          },
          {
            value: AssetTypeEnum.Savings,
            label: 'Savings',
          },
          {
            value: AssetTypeEnum.Stocks,
            label: 'Stocks',
          },
          {
            value: AssetTypeEnum.TrustFunds,
            label: 'Trust Funds',
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].accountNumber',
        placeholder: 'Account Number',
        formFieldType: 'text',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].monthlyAmount',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        placeholder: 'Amount',
        formFieldType: 'currency',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Account Belongs to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this asset',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'asset',
                  field: 'loan.$$custom.loan.assets[]',
                  featureId: 'asset-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageD: {
    title: 'Please provide information for your <strong>gifts or grants</strong>',
    pageId: 'gifts-grants',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are gifts and grants?`,
          body: `
            <h3>Gift Fund</h3>
            <p>Gift money which is given to you from a donor (relative, benefactor, etc). If you have a cash gift and intend to use it as a down-payment (purchase) and your closing cost, we will need to know if it has already been deposited into your banking/financial account. Gift funds cannot be used to purchase investment properties.</p>
            <h3>Gift of Equity</h3>
            <p>A gift of equity generally involves the sale of a property to a family member or someone with whom the seller has a close relationship, at a price below the current market value.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].assetType',
        placeholder: 'Gift Funds & Grant Type',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Gift Funds',
            value: AssetTypeEnum.GiftFunds,
          },
          {
            label: 'Gift of Equity',
            value: AssetTypeEnum.GiftOfEquity,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].monthlyAmount',
        prefix: '$',
        placeholder: 'Amount',
        formFieldType: 'currency',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Gifted or Granted to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this asset',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'asset',
                  field: 'loan.$$custom.loan.assets[]',
                  featureId: 'asset-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  },

  guidPageE: {
    title: 'Please provide information on your <strong>additional assets</strong>',
    pageId: 'other-assets',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are other assets?`,
          body: `
            <h3>Escrow Deposit</h3>
            <p>In a home purchase, earnest money is a deposit made to a seller that represents a buyer's good faith to buy a home.</p>
            <h3>Other Liquid Asset</h3>
            <p>Any asset that can readily be converted to cash while retaining its market value. This can be stocks, investments, and bonds, or access to your checking or savings account.</p>
            <h3>Other Non-Liquid Asset</h3>
            <p>Assets such as land and real estate investments that may take months to convert to cash.</p>
            <h3>Proceeds from a Property Sale</h3>
            <p>Money or other property received as the result of a sale that will be used to finance your new loan.</p>
            <h3>Secured Borrower Funds</h3>
            <p>Secured debt is money borrowed that is guaranteed (or secured) by the borrower's funds or assets and held by the lender in an interest-bearing account. These are an acceptable source of funds for the down payment, closing costs, and reserves. Assets that may be used to secure funds include automobiles, artwork, collectibles, real estate, or financial assets.</p>
            <h3>Cash on Hand</h3>
            <p>Cash on hand is the total amount of any accessible cash. In other words, money a business or person has after all costs have been paid, whether it's in your pocket or in your bank account.</p>
            <h3>Net Equity</h3>
            <p>Your total net equity is the value left after subtracting total liabilities from total assets.</p>
            <h3>Net Worth of Business Owned</h3>
            <p>This is considered the book value of a shareholder's equity reflected on a balance sheet. To calculate business net worth, subtract all libabilities (business loans, employee salary) from all your assets (cash, equipment, owned buildings, etc).</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].assetType',
        placeholder: 'Other Asset Types',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Escrow Deposit',
            value: AssetTypeEnum.EscrowDeposit,
          },
          {
            label: 'Proceeds from a Property Sale',
            value: AssetTypeEnum.ProceedsfromaPropertySale,
          },
          {
            label: 'Secured Borrower Funds Not Deposited',
            value: AssetTypeEnum.SecuredBorrowerFundsNotDeposited,
          },
          {
            label: 'Cash On Hand',
            value: AssetTypeEnum.CashOnHand,
          },
          {
            label: 'Net Equity',
            value: AssetTypeEnum.NetEquity,
          },
          {
            label: 'Net Worth of Business Owned',
            value: AssetTypeEnum.NetWorthOfBusinessOwned,
          },
          {
            label: 'Other Liquid Asset',
            value: AssetTypeEnum.OtherLiquidAsset,
          },
          {
            label: 'Other Non Liquid Asset',
            value: AssetTypeEnum.OtherNonLiquidAsset,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].description',
        placeholder: 'Other Asset Description',
        formFieldType: 'text',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.assets[].assetType',
              operator: RuleExprOp.EQ,
              value: AssetTypeEnum.Other,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].monthlyAmount',
        prefix: '$',
        placeholder: 'Amount',
        formFieldType: 'currency',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Account Belongs to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this asset',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'asset',
                  field: 'loan.$$custom.loan.assets[]',
                  featureId: 'asset-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  },

  guidPageZ: {
    title: 'Assets Review Section',
    titleShow: false,
    pageId: 'summary',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary-assets',
      },
    ],
  },
};
