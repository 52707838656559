import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, first, map, mapTo, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { merge, of, Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from '$env';
import { ApiService } from '$api';
import { AppSettings } from '../app.settings';
import { ModalsService } from 'src/app/components/modals/modals.service';
import { throwError, Subscription, fromEvent } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { UIStoreService } from '$ui';
import { ApplicationNavigationService } from './application-navigation.service';
import {
  ICPOSSrvApiResponse,
  IAuthenticationResponseViewModel,
  AuthenticationStatusEnum,
  IBranchViewModel,
  IChannelViewModel,
  IDivisionViewModel,
  ApplicationStatusEnum
} from 'src/app/shared/models';
import { CookieService } from 'ngx-cookie-service';
import { LoanUtils } from '../utils/loan-utils';
import { ICPOSSessionInfo } from '../models';

interface LogInFormData {
  userName: string;
  password: string;
  loanId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /** Is user logged in */
  public isLoggedIn = false;
  /** Is session expired */
  public sessionExpired = false;
  /** How long to show the modal window in seconds */
  public modalDuration = 60;
  /** Holds the logout session timer */
  public sessionTimer: any = null; //
  /** Holds reference to logout modal */
  public logOutModal: MatDialogRef<any>;
  /** Is a Ping currently in progress */
  private _isPinging = false;
  public isPinging$ = new BehaviorSubject(false);
  public get isPinging(): boolean {
    return this._isPinging;
  }
  public set isPinging(value: boolean) {
    this.isPinging$.next(value);
    this._isPinging = value;
  }
  /** If a no user activity is detected in this amount of time,
   * show log out modal and eventually log the user out automatically */
  private setTimerDefaultSeconds = 60 * 58; // tokens last 60 minutes, back off 2 minutes to handle server latency
  /** Manage the subscription for the keep alive */
  private keepAliveSub: Subscription;
  /** Actions that must be performed prior to logging out */
  private preLogOutActions: (() => Observable<any>)[] = [];
  /** Holds the minutes given from Session Expiration Config */
  private configuredMinutes: any = null;
  /** Minimum allowed configurable minutes **/
  private minimumMinutes: number = 5;
  /** Maximum allowed configurable minutes **/
  private maximumMinutes: number = 200;
  /** If user is already authorized, do not ping SecurityService when setting timer for session expiration **/
  private checkAuthorization: boolean = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    private settings: AppSettings,
    private modals: ModalsService,
    private api: ApiService,
    private analytics: AnalyticsService,
    private ui: UIStoreService,
    private appNavService: ApplicationNavigationService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
  ) {
    // On service load, if auth is enabled and a token is present start timer
    // This accomodates reloading the app
    if (environment.settings.enableAuth && this.settings.token) {
      // If reloading and not logging in
      this.checkAuthorization = false;
      this.keepAlive();
    }
  }

  private getFormDataForAuth(): FormData {
    const formData = new FormData();
    formData.append('client_id', `MmlOAuthClientDescription`);
    formData.append('redirect_uri', `https://qa01concierge.newleaflending.com`);
    formData.append('scope', `scope-mml-lc`);
    formData.append('response_type', `token`);
    return formData;
  }

  /**
   * Log the user in
   * @param data
   */
  public logIn(data: LogInFormData) {
    // Error handling
    if (!(data && data.userName && data.password)) {
      return;
    }

    // Prepare data for second API call
    // OAuth endpoint must be sent x-www-form-urlencoded data
    const formData = this.getFormDataForAuth();
    formData.append('username', data.userName);
    formData.append('password', data.password);
    formData.append('leadSourceId', this.settings.lsid);
    formData.append('loanId', data.loanId);

    // Auth point is configured
    const url = this.settings.apiUrl + '/api' + environment.endpoints.authLogin;
    return this.http
      .post<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>(url, formData, { observe: 'response' })
      .pipe(
        tap(response => {
          if (response) {
            this.handleAuthResponseHeaders(response);
            if (response.body && response.body.response) {
              this.handleAuthResponseBody(response.body.response);
            }
          }
        }),
        switchMap(response => this.validateLsid(response)),
        map(response => {
          const serverResponse = (response && response.body && response.body.response) || {};
          return <IAuthenticationResponseViewModel>{
            ...serverResponse,
            mfaToken: response.headers.get('x-cv-mfa-token'),
          };
        }),
        // We need to wait for to the loan to finish loading, but return
        // the API response of the Auth service
        switchMap(responseBody => {
          return of(responseBody);
        }),
      );
  } // end LogIn

  public initiateSignOn(landingUrl: string) {
    this.api.sso.initiateSignOn().pipe(
      tap(signOnUrl => {
        window.location.href = `${signOnUrl}?lsId=${this.settings.lsid}&relayState=${landingUrl}`;
      }),
      catchError(() => {
        return throwError('Unfortunately an error has occurred. Please try to refresh the page.');
      })
    ).subscribe();
  }

  public processSSOAuthentication(loanTokenFromUrl: string) {
    //if token query param exists, this means we have secure link sent and try to process user activation and loan basic data
    if (loanTokenFromUrl) {
      this.checkUserActivationByToken(loanTokenFromUrl)
        .pipe(take(1))
        .subscribe(activationResponse => {
          if (!activationResponse) return;
          if (activationResponse && activationResponse.isValidUserAccount === true) {
            LoanUtils.setSecureLinkId(this.route.snapshot.queryParams, this.settings);
            this.authenticateWithSSOToken();
          }
        });
    }
    else {
      this.authenticateWithSSOToken();
    }
  }

  public authenticateWithSSOToken() {
    const formData = this.getFormDataForAuth();

    return this.api.sso.authenticateWithSSSToken(formData).pipe(
      tap(response => {
        if (response) {
          this.handleAuthResponseHeaders(response);
          if (response.body && response.body.response) {
            this.handleAuthResponseBody(response.body.response);

          }
        }
      }),
      switchMap(response => this.validateLsid(response)),
      map(response => {
        const serverResponse = (response && response.body && response.body.response) || {};
        return <IAuthenticationResponseViewModel>{
          ...serverResponse
        };
      }),
      // We need to wait for to the loan to finish loading, but return
      // the API response of the Auth service
      switchMap(responseBody => {
        return of(responseBody);
      }),
      catchError(() => {
        return throwError('Unfortunately an error has occurred. Please try to refresh the page.');
      })
    ).subscribe(
      (apiResponse) => {
        if (apiResponse.succeeded) {
          let returnUrl = '/';
          // In order to view the series of mixpanel events triggered in Dev Console.
          this.handlePostSignInOnSuccess(returnUrl, apiResponse.authentication.userAccountName);

        } else if (apiResponse.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
          // Account lock was detected
          this.router.navigate(['/authentication-issue'], { state: { errorMessage: 'Your account is locked!' } });
        } else {
          // Generic error message
          this.router.navigate(['/authentication-issue'], { state: { errorMessage: 'An error occured during SSO login!' } });
        }
      },
      error => {
        error.errorMsg = 'Error logging in.';
        if ((error.statusText === 'Unauthorized')) {
          error.errorMsg = 'Invalid username or password, please try again.';
        }
      },
    );
  }

  /**
   * There are situations where we need to validate the LSID being saved in localStorage
   * Here, we check to see if those conditions are true, make a synchronous API call, and
   * always return the data that originally passed into this method
   * @param response Original response from the API
   */
  private validateLsid(response: HttpResponse<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>) {
    // If LSID needs validated...
    if (
      response &&
      response.body &&
      response.body.response &&
      response.body.response.authentication &&
      response.body.response.authentication.registeredLeadSourceId &&
      (this.settings.isDefaultCorporateLeadSource || !this.settings.lsid)
    ) {
      // Synchronous API call
      return this.api.broker.get(response.body.response.authentication.registeredLeadSourceId).pipe(
        tap(leadSourceInfo => {
          if (leadSourceInfo.leadSourceId) {
            this.settings.lsid = leadSourceInfo.leadSourceId;
          }
          if (leadSourceInfo.userAccountId) {
            this.settings.lsidUserId = leadSourceInfo.userAccountId.toString();
          }
        }),
        // Still, we return the original data passed in
        mapTo(response),
      );
    }
    // ... otherwise, simply pass the data on through
    return of(response);
  }

  /**
   * Log the user in via MFA
   * @param username Account username to login
   * @param mfaCode MFA code from form
   * @param mfaToken MFA token from API
   */
  public logInMfa(username: string, mfaCode: string, mfaToken: string) {
    // Error handling
    if (!(username && mfaCode && mfaToken)) {
      return of(<IAuthenticationResponseViewModel>{});
    }

    // Auth point is configured
    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('x-cv-mfa-token', mfaToken);
    const url =
      this.settings.apiUrl + '/api' + environment.endpoints.authLoginMfa + `/?userName=${username}&mfaCode=${mfaCode}`;
    return this.http
      .post<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>(url, null, {
        observe: 'response',
        headers: headersObj,
      })
      .pipe(
        tap(response => {
          if (response) {
            this.handleAuthResponseHeaders(response);
            if (response.body && response.body.response) {
              this.handleAuthResponseBody(response.body.response);
            }
          }
        }),
        switchMap(response => this.validateLsid(response)),
        map((response: HttpResponse<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>) => {
          return response.body.response;
        }),
        // We need to wait for to the loan to finish loading, but return
        // the API response of the Auth service
        switchMap(responseBody => {
          if (this.settings.loanId) {
            return this.api.megaLoan.get().pipe(mapTo(responseBody));
          }
          return of(responseBody);
        }),
      );
  } // end LogIn

  /**
   * Request a new MFA code
   * @param username
   * @param mfaToken
   */
  public requestNewMfa(username: string, mfaToken: string) {
    // Error handling
    if (!(username && mfaToken)) {
      return of(<IAuthenticationResponseViewModel>{});
    }

    // Auth point is configured
    let headersObj: HttpHeaders = new HttpHeaders();
    headersObj = headersObj.set('x-cv-mfa-token', mfaToken);
    const url = this.settings.apiUrl + '/api' + environment.endpoints.authRequestNewMfa + `/?userName=${username}`;
    return this.http
      .post<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>(url, null, {
        observe: 'response',
        headers: headersObj,
      })
      .pipe(
        tap(response => {
          if (response) {
            this.handleAuthResponseHeaders(response);
            if (response.body && response.body.response) {
              this.handleAuthResponseBody(response.body.response);
            }
          }
        }),
        switchMap(response => this.validateLsid(response)),
        map((response: HttpResponse<ICPOSSrvApiResponse<IAuthenticationResponseViewModel>>) => {
          const serverResponse = (response && response.body && response.body.response) || {};
          return <IAuthenticationResponseViewModel>{
            ...serverResponse,
            mfaToken: response.headers.get('x-cv-mfa-token'),
          };
        }),
      );
  } // end requestNewMfa

  /**
   * Used to create a new user and loan
   * @param data
   */
  public async registerNewUser(data: any) {
    const userAccountId = this.settings.lsidUserId;
    const callTimestamp = new Date().getTime().toString();
    const registerUrl = `${this.settings.apiUrl}/api/users/register`;


    let branch: any, channel: any, division: any;
    const apiResponse = await this.api.companyBasicInfo.getForUser(this.settings.lsid).pipe(take(1)).toPromise();

    const company = apiResponse.response;
      channel = company.channels
      && Array.isArray(company.channels)
      && company.channels[0]
        ? company.channels[0]
        : <IChannelViewModel>{};
      division = channel
      && channel.divisions
      && Array.isArray(channel.divisions)
      && channel.divisions[0]
        ? channel.divisions[0]
        : <IDivisionViewModel>{};
      branch = division
      && division.branches
      && Array.isArray(division.branches)
      && division.branches[0]
        ? division.branches[0]
        : <IBranchViewModel>{};

    const requestBody = {
      authenticationRequestViewModel: {
        authenticationRequestType: 10,
        authentication: {
          firstName: data.firstName,
          lastName: data.lastName,
          userAccountName: data.email,
          password: data.password,
          branchId:branch.branchId,
          channelId:channel.channelId,
          divisionId: division.divisionId
        },
      },
    };

    const params = {
      userAccountId,
      callTimestamp,
    };

    const SSOConfigValue = this.settings.config["ConsumerSite.SSO.Enabled"]?.value || 0;
    var useCredentials = false;
    if (SSOConfigValue == 1 || SSOConfigValue == 2) {
      useCredentials = true;
    }

    return this.http
      .post<IAuthenticationResponseViewModel>(registerUrl, requestBody, { observe: 'response', params, withCredentials: useCredentials })
      .pipe(
        tap((response: HttpResponse<IAuthenticationResponseViewModel>) => {
          if (response) {
            this.handleAuthResponseHeaders(response);
            if (response.body) {
              this.handleAuthResponseBody(response.body);
            }
          }
        }),
        map((response: HttpResponse<IAuthenticationResponseViewModel>) => {
          const serverResponse = (response && response.body) || <IAuthenticationResponseViewModel>{};
          return <IAuthenticationResponseViewModel>{
            ...serverResponse,
            mfaToken: response.headers.get('x-cv-mfa-token'),
          };
        }),
        catchError(() => {
          // Reformat all errors to a single string here:
          return throwError(`Unfortunately an error has occurred. Please try again.`);
        }),
      );
  }

  /**
   * Used by both login and register to handle an authenticated response
   * @param response
   */
  public handleAuthResponseHeaders(response: HttpResponse<any>): void {
    // Store the auth token
    const token = response.headers.get('authorization');
    if (token) {
      this.settings.token = token;
      this.isLoggedIn = true;
    } else {
      console.log('Missing Oauth token in the response header', response.url, response.body, response.status, response.headers);
    }
  }

  /**
   * Used by both login and register to handle an authenticated response
   * @param response
   */
  public handleAuthResponseBody(response: IAuthenticationResponseViewModel): void {
    // console.log('handleAuthResponseBody', this.settings.userId);
    if (!(response && response.authentication)) {
      console.error(`AUTH ENDPOINT DID NOT RETURN AUTHENTICATION DATA`);
    }
    // Store the loan ID when first logging in
    if (!this.settings.loanId) {
      this.settings.loanId = response.authentication.loanId || null;
    }
    // Store the user ID when first logging in
    this.settings.userId =
      (response.authentication.accountIds &&
        response.authentication.accountIds.length &&
        response.authentication.accountIds[0] &&
        response.authentication.accountIds[0].toString &&
        response.authentication.accountIds[0].toString()) ||
      null;
    // Store the userName when first logging in
    this.settings.userName = response.authentication.userAccountName || null;
    this.settings.userFullName = response.authentication.firstName + ' ' + response.authentication.lastName || null;
    this.settings.ssoUser = response.authentication.isSSOUser || false;

    this.analytics.mixpanelSuperProps({ 'Loan Id': this.settings.loanId });
    this.analytics.mixpanelSuperProps({ 'CV Guid': this.settings.loanId });

    this.checkAuthorization = false;
    this.keepAlive();
    this.sessionExpired = false;

    // Clear the opened loans session cookie in case the browser (e.g., Chrome, Edge) settings have a
    // pick-up-where-you-left-off option that keeps cookies accross sesions.
    this.clearOpenedLoanIdsCookie();
  }

  private clearOpenedLoanIdsCookie(): void {
    const cookieDomain = this.settings.cookieDomain;
    const cookieName = "OPENED_LOAN_IDS";

    this.cookieService.delete(cookieName, "/", cookieDomain);
  }

  /**
   * Set a countdown timer that pops a modal window when the user is close to session timeout
   * @param expirationSeconds Seconds before automatically logging out user
   */
  private setTimer(expirationSeconds: number): void {
    clearTimeout(this.sessionTimer);
    if (this.settings.token) {
      this.sessionTimer = setTimeout(() => {
        this.sessionExpired = true;
        this.launchLogoutModal();
        // Show the modal (10 seconds + modalDuration) before time runs out.
      }, (expirationSeconds - this.modalDuration - 10) * 1000);
    }
  } // end SetTimer

  /**
   * Notify the server that the session is active by polling based on user interaction events
   */
  private keepAlive() {
    this.keepAliveSub = merge(
      fromEvent(document, 'mousemove'), // On mouse move
      fromEvent(document, 'touchmove'), // On touch move
      fromEvent(document, 'keyup'), // On key up
    )
      .pipe(throttleTime(60 * 1 * 1000)) // ping every minute
      .subscribe(() => {
        // Send ping to the back-end
        if (this.settings.token && this.checkAuthorization) {
          this.isPinging = true;
          const formData = this.getFormDataForAuth();
          formData.append('loanId', this.settings.loanApplicationId);

          this.http
            .post(this.settings.apiUrl + '/api/SecurityService/Ping', formData, { observe: 'response' })
            .pipe(
              tap((response: HttpResponse<any>) => {
                // If token is valid then reset session timer
                // (let user continue his work, during next API call token will be reissued if needed)
                if (response && response.status == 200) {
                  this.handleAuthResponseHeaders(response);

                  this.setSessionExpirationTimer();
                }
              this.isPinging = false;
              }),
              catchError((err) => {
                console.error(`Error executing ping service.`);
                this.isPinging = false;
                return of(err);
              }),
            )
            .subscribe();
        }
        else {
          //session expiration initial setup on login, registration or page refresh, 
          //when this.checkAuthorization is false
          this.setSessionExpirationTimer();
          //after initial authorization, in order to call SecurityService/Ping next time, 
          //we are setting flag to true
          this.checkAuthorization = true;
        }
      });
  }

  private setSessionExpirationTimer(): void {
    // Session Expiration Configuration, 5 < minutes < 200, otherwise default 5||200
    if (this.settings.config['cPOS Session Expiration (Minutes)']) {
      this.configuredMinutes = this.settings.config['cPOS Session Expiration (Minutes)'].value;
      this.setTimerDefaultSeconds =
        Math.min(Math.max(this.configuredMinutes, this.minimumMinutes), this.maximumMinutes) * 60;
    }
    // Reset automatic logout modal timer
    this.setTimer(this.setTimerDefaultSeconds);
  }

  /**
   * Launch a modal window which gives the user a chance to continue working
   */
  private launchLogoutModal(): void {
    clearTimeout(this.sessionTimer);
    // Open log out modal window
    this.modals
      .open('LogoutModalComponent', false, 'sm', {
        modalDuration: this.modalDuration,
        settings: this.settings,
        sessionExpirationSeconds: this.setTimerDefaultSeconds
      })
      .afterClosed()
      .subscribe((sessionInfo: ICPOSSessionInfo) => {
        if (sessionInfo.reason !== true) {
          this.checkAuthorization = true;
          this.keepAlive();
        } else {
          this.logOut(undefined, undefined, sessionInfo.idleTimeout);
        }
      });
  }

  /**
   * Clear stored data
   */
  public resetStoreAndSettings(): void {
    this.settings.token = null;
    this.settings.borrowerId = null;
    this.settings.loanId = null;
    this.settings.loanApplicationId = null;
    this.settings.userId = null;
    this.settings.loUserIdOriginal = null;
    this.ui.form1003 = null;
    this.settings.userName = null;
    this.settings.canSaveAppState = null;
    this.api.appState$.next(null);
    if (this.keepAliveSub && this.keepAliveSub.unsubscribe) {
      this.keepAliveSub.unsubscribe();
    }
    this.api.resetStore(); // Clear out all API data on log out for security
  }

  /**
   * Log the user out
   */
  public logOut(showLogoutMessage = false, forcedQueryParams: Params = {}, idleTimeout?: number): void {
    this.performPreLogOutActions().subscribe(() => {
      this.preLogOutActions = [];
      if (this.settings.ssoUser) {
        this.api.sso.signOutSSO().pipe(
          tap(signOutUrl => {
            window.location.href = signOutUrl;
          }),
          catchError(() => {
            return throwError('Unfortunately an error has occurred. Please try to refresh the page.');
          })
        ).subscribe();
      } else {
        this.processBorrowerInactivityOnClose(idleTimeout);
        this.redirectToAuthenticate(showLogoutMessage, forcedQueryParams, '/login');
      }
      this.appNavService.appRouting$.next({ sectionId: null, pageId: null });
    });
  }

  /**
   * unsubscribe from services
   */
  public unsubscribeServices(): void {
    this.api.evoievoe.unsubscribe();
  }

  /**
   * Attach a callback that must be performed prior to logging out. The callback
   * must return an observable that completes. Upon log out, the
   * auth service will execute the pre log out action and wait until the observable
   * returned completes before logging out.
   *
   * Returns an unsubscribe function that can remove the pre logout action.
   */
  public attachPreLogOutAction(action: () => Observable<any>): () => void {
    this.preLogOutActions.push(action);
    return () => {
      this.preLogOutActions = this.preLogOutActions.filter(a => a !== action);
    };
  }

  /**
   * Performs all pre logout actions in parallel and returns an observable
   * that completes when all pre logout actions have completed
   */
  private performPreLogOutActions(): Observable<any> {
    const actions = this.preLogOutActions;
    if (!(actions && actions.length)) {
      return of(true);
    }

    const actionObservables: Observable<any>[] = actions.map(fn => {
      try {
        return fn().pipe(catchError(err => of(err)));
      } catch (err) {
        return of(err);
      }
    });

    return forkJoin(actionObservables);
  }

  /**
   * Clear stored data and redirect user to authenticate
   */
  public redirectToAuthenticate(showLogoutMessage = false, forcedQueryParams: Params = {}, forcedUrl?: string): void {
    clearTimeout(this.sessionTimer);
    // Clear stored data
    this.resetStoreAndSettings();
    // Don't throw a redirect url if this is the dashboard since that is default on login
    const returnUrlSplit = (this.router.url && this.router.url.split && this.router.url.split('?')[0]) || '/';
    const returnUrl =
      returnUrlSplit !== '/' && returnUrlSplit !== '/login' && returnUrlSplit !== '/register' ? returnUrlSplit : null;
    // Determine whether or not to show the log out message
    let newQueryParams = showLogoutMessage ? { returnUrl: returnUrl, session: 'loggedout' } : { returnUrl: returnUrl };
    if (this.sessionExpired == true) {
      newQueryParams = { returnUrl: returnUrl, session: 'expired' };
    }
    const queryParams = { ...forcedQueryParams, ...newQueryParams };
    // Use forced URL if specified
    const url = !!forcedUrl ? forcedUrl : this.settings.lsid ? '/register' : '/login';
    this.router.navigate([url], { queryParams: queryParams, queryParamsHandling: 'merge' });
  } // end LogOut


  public redirectToSSOInfoPage(forcedQueryParams: Params = {}): void {
    clearTimeout(this.sessionTimer);
    // Clear stored data
    this.resetStoreAndSettings();
    this.router.navigate(['/authentication-inprogress'], { queryParams: forcedQueryParams, queryParamsHandling: 'merge' });
  }

  /**
   * Determine if a user account has been activated. Return activation status and username if one was found
   * @param token SecureLinkId token
   */
  checkUserActivationByToken(token: string) {
    return this.api.userAccount.getAccountInfo(token).pipe(
      switchMap(apiResponse => {
        const userName = (apiResponse && apiResponse.response && apiResponse.response.signinUserEmailAddress) || '';

        return this.api.userAccount.checkActivationByUserName(userName).pipe(
          map(activationResponse => {
            if (activationResponse && activationResponse.response) {
              activationResponse.response.loanId = apiResponse.response.loanId;
              this.settings.loanApplicationId = this.settings.loanId = apiResponse.response.loanApplicationId;
            }
            return activationResponse && activationResponse.response;
          }),
        );
      }),
    );
  }

  public unloadLogout() {
    this.preLogOutActions = [];
    clearTimeout(this.sessionTimer);
    this.resetStoreAndSettings();
    this.appNavService.appRouting$.next({ sectionId: null, pageId: null });
  }

  public handlePostSignInOnSuccess(returnUrl: string, username: string) {
    let returnUrlFinal = returnUrl;
    this.analytics.mixpanelSetDebugTrue(true);
    this.analytics.mixpanelIdentify(this.settings.userId);
    this.analytics.mixpanelPeople({ 'User': 'Borrower' });
    this.analytics.trackEvent('Please Log In BC');
    this.analytics.trackEvent('Login Success BC');

    if (!(this.route.snapshot.queryParams['token'])) {
      returnUrlFinal = '/my-loans';
    }

    // Store username in localstorage
    this.settings.Storage.setItem('userName', username);
    // Navigate to the proper route
    this.router.navigate([returnUrlFinal], { queryParamsHandling: 'merge' });
  }

  public processSingleUserReleaseLockOnClose() {
    const releaseLockUrl = `${this.settings.apiUrl}/api/loan/SingleUserReleaseLock?loanId=${this.settings.loanId}&UserAccountId=${this.settings.userId}`;
    this.api.executeFetchCall(releaseLockUrl, null);
  }

  public processBorrowerInactivityOnClose(idleTimeout?: number) {
    const newLeadCreationEnabled = this.settings.config["NewLeadCreation.Enabled"]?.value || 0;
    if (newLeadCreationEnabled) {
      this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
        first(),
        map(loan => loan.applicationStatus),
        tap(currentAppStatus => {
          if (currentAppStatus == ApplicationStatusEnum.None) {
            this.api.leadNotifications.processLeadCreation(false, idleTimeout);
          }
        })
      ).subscribe();
    }
  }
}
